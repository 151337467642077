global.jQuery = require('jquery')
global.$ = global.jQuery
require('tether')
require('bootstrap')
require('popper.js')
require('./index.scss')

$(window).on('load', function() {
  $('.fullpage.intro').css( 'height', window.innerHeight ).scrollTop()
  // $('.fullpage').first().css('margin-top', window.innerHeight )
  $('.loading').fadeOut(250, function() {
    $(this).remove()
  })
  $('.container-fluid').removeClass('d-none')
  $('.container-fluid').addClass('animate-opacity')
  $('.intro').addClass('animate-moveup')
  // $('.container-fluid').animate({
  //   opacity: 1,
  // }, 250)
  // $('.fullpage').first().animate({
  //   'margin-top': 0,
  // }, 350)

});


const styleGreen = 'color:white; background:#61ba96'
const styleRed = 'color:white; background:#d36a6a'
const stylePurple = 'color:white; background:#ba75ff'

const VERSION = require('./../package.json').version
console.log(`%c threethink %c mharchitektur  %c v${VERSION} `, stylePurple, styleGreen, styleRed)
